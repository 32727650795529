import React, { useEffect } from "react"
import Layout from '../components/layout'
import Head from '../components/head'
import { navigate } from 'gatsby'
import '../styles/style.scss'

// markup
const IndexPage = () => {
  useEffect(() => {
    navigate('/events');
  }, [])

  return (
    <>
      <Head title='Mana Common Homepage' />
      <Layout>
        <div className="redirect-content">Redirecting to events page....</div>
      </Layout>
    </>
  )
}

export default IndexPage
